import * as React from "react"
import { graphql } from "gatsby"

import Page from '../../components/Page'
import Seo from "../../components/Seo";

import HeroSection from "../../components/Edge/HeroSection";
import IntensiveUseCases from "../../components/Edge/IntensiveUseCases";
import CriticalApplications from "../../components/Edge/CriticalApplications,jsx";
import DevicesSupport from "../../components/Edge/DevicesSupport";
import AccrossIndustries from "../../components/Edge/AccrossIndustries";

const LooprModels = (props) => {
  const { attributes } = props.data.strapidata.looprEdge.data
  const { seo } = attributes
  return (
    <Page className="products loopr-data">
      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        pimage={seo.shareImage.data.attributes.url}
        cpath = {props.location.pathname}
        />
        <HeroSection data={attributes} />
        <IntensiveUseCases data={attributes} />
        <CriticalApplications data={attributes} />
        <DevicesSupport data={attributes} />
        <AccrossIndustries data={attributes} />
    </Page>
  )
}

export default LooprModels


export const query = graphql`
query LooprEdgeQuery {
  strapidata {
    looprEdge {
      data {
        attributes {
          seo {
            metaTitle
            metaDescription
            shareImage {
              data {
                attributes {
                  url
                }
              }
            }
          }
          name
          shortDescription
          buttonText
          buttonLink
          intensiveUseCasesHeading
          intensiveUseCasesList {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          IntensiveAdvantagesHeading
          IntensiveAdvantagesList {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          criticalApplicationsHeading
          criticalApplicationsList {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          criticalApplicationAdvantagesHeading
          criticalApplicationAdvantagesList {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          devicesWeSupportHeading
          devicesWeSupportList {
            title
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
          acrossIndustriesHeading
          acrossIndustriesItems {
            title
            description
            image {
              data {
                attributes {
                  url
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
}
`