import * as React from "react"
import { Link } from "gatsby"
const HeroSection = ({ data }) => {
    const { name, shortDescription, buttonText} = data
    return (
        <section className="mt-8">
            <div className="max-w-6xl mx-auto px-4 py-24">
                <h6 className="text-gray-400 text-lg font-semibold tracking-wider mb-1">Products</h6>
                <h2 className='md:text-4xl text-2xl font-medium  mb-3'>{name} </h2>
                <p className="lg:w-5/12 mb-8">{shortDescription}</p>
                <Link to="/contact-us" className='flex justify-center justify-items-center content-center w-44 py-3 mb-3 border-2 font-bold border-blue-500 text-blue-500 capitalize text-lg hover:bg-blue-500 hover:text-white'><span>{buttonText}</span></Link>
            </div>
        </section>
    )
}

export default HeroSection