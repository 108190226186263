import * as React from "react"
import ResponsiveImage from '../Image'
const DevicesSupport = ({ data }) => {
    const { devicesWeSupportHeading, devicesWeSupportList } = data
    return (
        <section className="bg-white md:py-20 py-10">
            <div className="lg:max-w-7xl px-4 w-full mx-auto flex md:flex-row flex-col">
                <div className='md:w-1/4 w-full'>
                    <h2 className='lg:w-60 sm:w-3/4 w-full font-medium capitalize lg:text-4xl text-3xl md:pb-0 pb-10 capitalize'>{devicesWeSupportHeading}</h2>
                </div>
                <div className='md:w-3/4 w-100'>
                    <p className='text-base lg:pl-14'>Deploy at the edge on IoT devices like – </p>
                    <div className='w-full flex flex-row flex-wrap justify-start mt-10 sm:gap-10 gap-4'>
                        {devicesWeSupportList && devicesWeSupportList.map((item, index)=>{
                            return(
                                <div className='sm:w-1/4 text-center flex flex-col justify-between support-logos' key={index}>
                                    {item?.image?.data?.attributes?.url && (
                                        <ResponsiveImage 
                                            large={item.image.data.attributes.url}
                                            medium={item.image.data.attributes.url}
                                            small={item.image.data.attributes.url}
                                            width={item.image.data.attributes.width}
                                            height={item.image.data.attributes.height}
                                        />
                                    )}                                    
                                    <div className='text-xl pt-2 pb-12 capitalize'>{item.title}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DevicesSupport