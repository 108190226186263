import * as React from "react"
import ResponsiveImage from '../Image'
const IntensiveUseCases = ({ data }) => {
    const { intensiveUseCasesHeading, intensiveUseCasesList, IntensiveAdvantagesHeading, IntensiveAdvantagesList } = data
    return (
        <>
            <section className="bg-gray-100 pt-24 pb-10">
                <div className="max-w-7xl mx-auto flex lg:flex-row flex-col px-4">
                    <div className="lg:w-1/4 w-full">
                        <h2 className="text-3xl font-medium mb-10 lg:w-52">{intensiveUseCasesHeading}</h2>
                    </div>
                    <div className="lg:w-3/4 w-full">
                        <div className="grid md:grid-cols-4 grid-cols-1 lg:pl-14">
                            {intensiveUseCasesList && intensiveUseCasesList.map((item, index)=>{
                                return(
                                    <div key={index} className="grid grid-cols-4 md:flex md:flex-col md:mb-0 md:items-start items-center">
                                        <div className="w-24 h-24 md:border-r-0 border-r-2 md:text-left text-center border-solid md:py-0 sm:py-12 py-20 col-span-1 md:items-start items-center">
                                        <ResponsiveImage 
                                            large={item.image.data.attributes.url}
                                            medium={item.image.data.attributes.url}
                                            small={item.image.data.attributes.url}
                                            width={item.image.data.attributes.width}
                                            height={item.image.data.attributes.height}
                                        />
                                        
                                        </div>
                                        <div className="sm:py-0 py-10 col-span-3">
                                            <div className="md:border-t-2 md:border-b-0 border-b-2 border-solid border-gray-500 w-full md:pt-4 md:mb-0 mb-5 relative">
                                                <svg className="absolute md:-top-2 -bottom-2 md:left-0 -left-2" xmlns="http://www.w3.org/2000/svg" width="12.367" height="12.383" viewBox="514.817 1738.8 12.367 12.383"><g data-name="Ellipse 35"><path d="M515 1744.942a6 6 0 1 1 0 .1z" fill="#fff" fillRule="evenodd" /><path d="M516 1744.95a5 5 0 1 1 0 .083z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.66666" stroke="#3c4fe0" fill="transparent" /></g></svg>
                                                <h6 className="text-gray-400 font-semibold mb-2 md:pl-0 pl-5">0{index+1}</h6>
                                                <h4 className="font-bold md:pl-0 pl-5 mb-2">{item.title}</h4>
                                            </div>
                                            <p className="md:pl-0 pl-5  xl:pr-10 md:pr-20 text-base text-gray-600 leading-5">{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                          
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100 pb-20 pt-10">
                <div className="max-w-7xl mx-auto flex lg:flex-row flex-col px-4">
                    <div className="lg:w-1/4 w-full">
                        <h2 className="text-3xl font-medium mb-10 lg:w-52">{IntensiveAdvantagesHeading}</h2>
                    </div>
                    <div className="lg:w-8/12 w-full">
                        <div className="grid sm:grid-cols-2 gap-16 grid-cols-1">
                            {IntensiveAdvantagesList && IntensiveAdvantagesList.map((item, index)=>{
                                return(
                                    <div className=" flex flex-col" key={index}>
                                        <div className="w-24 h-24 mb-3">
                                        <ResponsiveImage 
                                            large={item.image.data.attributes.url}
                                            medium={item.image.data.attributes.url}
                                            small={item.image.data.attributes.url}
                                            width={item.image.data.attributes.width}
                                            height={item.image.data.attributes.height}
                                        />
                                           </div>
                                        <div>
                                            <h4 className="font-bold mb-3">{item.title}</h4>
                                            <p className="text-base text-gray-600 leading-5">{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })}                          
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IntensiveUseCases